import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CONFIG } from 'src/app/configuration/app-config';
import { Site } from 'src/app/library/interfaces/general/Site';
import { HttpService } from 'src/app/services/networking/rest.service';
import { LookupService } from 'src/app/services/other/lookup.service';
import { State } from 'src/app/state/app-state.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {

  displayedColumns: string[] = ['name', 'modified', 'city'];
  dataSource : any = null;
  dataLength : number;


  sites : Array<Site>;

  constructor(private stateService : State, private router : Router) { }

  ngOnInit(): void {
    this.stateService.sites$.subscribe((sites)=>{
      console.log(sites);
      if(sites){
        this.sites = sites;
        this.dataSource = sites;
        this.dataLength = sites.length
      }

    })
  }

  public open(element) { 
    this.router.navigate([`resources/sites/update/${element.id}`]);
  }

  public search(event) { 
    if(event.searchTerm){
      this.dataSource = this.sites.filter((site) => ((site.name).toLowerCase()).indexOf((event.searchTerm).toLowerCase()) >= 0);
    } else {
      this.dataSource = this.sites;
    }
  }

}
