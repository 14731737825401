import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from 'src/assets/scripts/ckeditor'

@Component({
  selector: 'richtext-editor',
  templateUrl: './richtext-editor.component.html',
  styleUrls: ['./richtext-editor.component.scss']
})
export class RichtextEditorComponent implements OnInit {

  @Output() dataChangeEvent = new EventEmitter<any>();

  public ckEditor = ClassicEditor;

  @Input() data: any;
  public config = {
    image: {
      // Configure the available styles.
      styles: [
        'alignLeft', 'alignCenter', 'alignRight'
      ],

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null
        },
        {
          name: 'resizeImage:25',
          label: '25%',
          value: '25'
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50'
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75'
        }
      ],
      toolbar: [
        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
        '|',
        'resizeImage'
      ]
    },
    fontSize: {
      options: [
        12,
        14,
        16,
        18,
        20,
        22,
        24,
        26,
        28,
        30,
        32,
        34,
        36,
        38,
        40
      ],
      supportAllValues: true
    },
    toolbar: [
      'alignment',
      'bold',
      'underline',
      'italic',
      'fontSize',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'uploadImage',
      'blockQuote',
      'undo',
      'redo'
    ],
  };
  constructor() { }

  ngOnInit() {
  }


  public onChange({ editor }: ChangeEvent) {
    try{
      let temp = editor?.getData();
      this.dataChangeEvent.emit(temp);
    }catch(ex){

    }
  }
}
