import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MODULES } from '../modules/modules.export';
import { SERVICES } from '../services/services.export';
import { Components } from '../components/components.export';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { FlexLayoutModule } from "@angular/flex-layout";
import { State } from '../state/app-state.service';
import { IconRegistry } from '../services/helpers/icons-registry.service';
import { AuthenticationService } from '../services/core/authentication.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileService } from '../services/account/profile.service';
// import { ProductsService } from '../services/sales/products.service';
import { FirebaseService } from '../services/core/firebase.service';
// import { CustomerService } from '../services/sales/customers.services';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { Interceptors } from '../library/interceptors/public.interceptors.export';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ResourcesService } from '../services/resources/resources.service';
import { SaleService } from '../services/sales/sale.service';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    ...Components,
    AppComponent
  ],
  imports: [
    ...MODULES,
    BrowserModule,
    FormsModule,
    CKEditorModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    State,
    Interceptors,
    ...SERVICES,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private firebaseService: FirebaseService,
    private iconRegistry: IconRegistry,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private stateService: State,
    private resourcesService : ResourcesService,
    private saleService : SaleService
  ) {
    this.iconRegistry.registerIcons(); // register all custom icons
    this.startApplication();
  }


  private async startApplication(): Promise<void> {
    try {
      this.stateService.appLoading = true;
      await this.authenticationService.start();
      await this.firebaseService.start();
      this.profileService.start();
      this.saleService.start();
      this.startWhenAuthenticated();
      this.stateService.appLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  /* these services on start if the user is authenticated */

  private startWhenAuthenticated(): void {
    this.stateService.authentication$
      .subscribe((state) => {
        if (state) {
          this.resourcesService.start();
          // this.productsService.start();
          // this.customersService.start();
        }
      })
  }
}
