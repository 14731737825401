import { Component, Input, OnInit, Output } from '@angular/core';

export interface TableConfig{ 
  actionHandleronClick  : Function;
  displayedColumns      : string[];
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() config : TableConfig;

  @Input() source : Array<any>;
  
  @Output() paginatorEvent;
  @Output() searchString;
  



  ngOnInit(){
   }



  /** Gets the total cost of all transactions. */
  getTotalCost() {
    return this.source.map(t => t.cost).reduce((acc, value) => acc + value, 0);
  }
}
