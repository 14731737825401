import { TitleCasePipe, CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StatusToFriendlyPipe } from 'src/app/library/pipes/friendly-string/status-to-friendly.pipe';
import { ProductTypeToFriendlyPipe } from 'src/app/library/pipes/friendly-string/productType-to-friendly.pipe';
import { EnumToArrayPipe } from 'src/app/library/pipes/utility-pipes/enum-to-array.pipe';
import { DayOfWeekToFriendlyPipe } from 'src/app/library/pipes/friendly-string/dayOfWeek-to-friendly.pipe';
import { NewsCategoryToFriendlyPipe } from "src/app/library/pipes/friendly-string/news-category-to-friendly.pipe";
import { EllipsisPipe } from "src/app/library/pipes/utility-pipes/ellipsis.pipe";
import { ActivityTypePipe } from "src/app/library/pipes/friendly-string/activityType.pipe";

@NgModule({
    declarations: [
        StatusToFriendlyPipe,
        ProductTypeToFriendlyPipe,
        EnumToArrayPipe,
        DayOfWeekToFriendlyPipe,
        NewsCategoryToFriendlyPipe,
        EllipsisPipe,
        ActivityTypePipe
    ],
    imports: [
        CommonModule
    ],
    providers: [
        TitleCasePipe,
    ],
    exports: [
        StatusToFriendlyPipe,
        ProductTypeToFriendlyPipe,
        EnumToArrayPipe,
        DayOfWeekToFriendlyPipe,
        NewsCategoryToFriendlyPipe,
        EllipsisPipe,
        ActivityTypePipe
    ]
})
export class PipesModule { }
