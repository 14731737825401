<div class="container">
    <div class='bread-crumb'>
        <h2 class='bread-crumb-title'>Sites</h2>
        <p><small>resources/sites/update</small></p>
        <hr class='bread-crumb-divider'>
    </div>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Details">
            <div *ngIf="site; else loader" class="standard-card tm-30">
                <form [formGroup]="form1" class="row full-width">

                    <h3 class="mat-title">Basic Details</h3>
                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput class="form-control" formControlName="name">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput class="form-control" formControlName="description">
                        </mat-form-field>
                    </div>


                    <div class="row-inner">

                    </div>

                    <h3 class="mat-title">Location</h3>
                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Latitude</mat-label>
                            <input matInput class="form-control" type="number" formControlName="latitude">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Longitude</mat-label>
                            <input matInput class="form-control" type="number" formControlName="longitude">
                        </mat-form-field>
                    </div>

                    <button class="standard-button" [disabled]="form1?.invalid" (click)="updateSite();" mat-button><small>Update Site</small></button>
                </form>
            </div>
            <ng-template #loader>
                <div class='spinner-loader table-loader'>
                    <mat-spinner class='center'></mat-spinner>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Activities">
            <button class="standard-button new-activity-button" (click)="createActivity()" mat-button><small>Create activity</small></button>
            <div class="activities-wrapper tm-30">
                <div matRipple [matRippleCentered]="true" (click)="updateActivity(activity)" [matRippleDisabled]="false" [matRippleUnbounded]="false" *ngFor="let activity of activities" class="standard-card activity-card">
                    <mat-icon>nature_people</mat-icon>
                    <div class="mat-subheading-2"><strong>{{activity.name}}</strong></div>
                    <div class="mat-caption activity-type">{{activity.activity | ActivityTypePipe}}</div>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</div>