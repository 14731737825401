<section class="top-section">
    <div class="full-width search-container">
        <mat-form-field appearance="fill">
            <mat-label>Search Product</mat-label>
            <input [(ngModel)]="searchTerm" (input)="filterItems($event)" matInput>
        </mat-form-field>
        <button mat-flat-button (click)="salesService.discardOrder()" color="primary">Discard Order</button>
    </div>

    <div *ngIf="saleType == 'Permits'" class="item-list permits-sale">
        <div *ngFor="let item of permits" (click)="salesService.addItemToOrder(item)" matRipple class="item">
            <div class="item-name">{{item.name}}</div>
            <div class="price">R{{item.baseCost}}</div>
        </div>
    </div>
    <div *ngIf="saleType == 'Hiking Trails'" class="item-list hiking-sale">
        <div *ngFor="let item of hikingTrails" (click)="salesService.addItemToOrder(item)" matRipple class="item">
            <div class="item-name">{{item.name}}</div>
            <div class="price">R{{item.baseCost}}</div>
        </div>
    </div>
</section>

<hr>
<section class="bottom-section">
    <div (click)="changeSaleType('Permits')" [ngClass]="{'button-active' : saleType == 'Permits'}" matRipple class="sale-category">
        <h6>Permits</h6>
    </div>
    <div (click)="changeSaleType('Hiking Trails')" [ngClass]="{'button-active' : saleType == 'Hiking Trails'}" matRipple class="sale-category">
        <h6>Multiday</h6>
    </div>
</section>