<div class="container">
    <div class='bread-crumb'>
        <h2 class='bread-crumb-title'>Bookings</h2>
        <p><small>resources/bookings</small></p>
        <hr class='bread-crumb-divider'>
    </div>
    <div class="standard-card">
        <div class="input-container">
            <mat-form-field appearance="fill">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [formControl]="start" placeholder="Start date">
                    <input matEndDate [formControl]="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Booking No.</mat-label>
                <input [formControl]="bookingNo" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Adventurer.</mat-label>
                <input [formControl]="adventurer" matInput>
            </mat-form-field>
        </div>

        <div class="card-bottom-container">
            <button mat-button class="standard-button" (click)="loadBookings()"><small>SEARCH</small></button>
        </div>

    </div>
    <div class="table-wrapper" *ngIf="bookings; else loader">
        <table mat-table [dataSource]="dataSource" matSort class="full-width">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Booking No.</th>
                <td mat-cell *matCellDef="let element"><small>{{element.id}}</small> </td>
            </ng-container>

            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Name.</th>
                <td mat-cell *matCellDef="let element"><small>{{element.adventurer.fullName}}</small> </td>
            </ng-container>

            <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef>Activity</th>
                <td mat-cell *matCellDef="let element"><small>{{element.activity.name}}</small></td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start</th>
                <td mat-cell *matCellDef="let element"><small>{{element.startDate}}</small></td>
            </ng-container>

            <ng-container matColumnDef="days">
                <th mat-header-cell *matHeaderCellDef>Days</th>
                <td mat-cell *matCellDef="let element"><small>{{element.activity.days}}</small></td>
            </ng-container>

            <ng-container matColumnDef="pax">
                <th mat-header-cell *matHeaderCellDef>People</th>
                <td mat-cell *matCellDef="let element"><small>{{element.pax}}</small></td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element"><small>{{element.bookingStatus.name}}</small></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [length]="bookings.length" pageSize="10"></mat-paginator>
    </div>
    <ng-template #loader>
        <div class='spinner-loader table-loader'>
            <mat-spinner class='center'></mat-spinner>
        </div>
    </ng-template>
</div>