<div class="container">
    <div class='bread-crumb'>
        <h2 class='bread-crumb-title'>Sites</h2>
        <p><small>resources/sites</small></p>
        <hr class='bread-crumb-divider'>
    </div>
    <div class="create-product-btn-wrapper">
        <button mat-button class="standard-button" routerLink="/resources/sites/create"><small>CREATE SITE</small></button>
    </div>
    <table-search (searchEvent)=search($event) (defaultStatuses)="[1,2]"></table-search>
    <div class="table-wrapper" *ngIf="dataSource; else loader">
        <table mat-table [dataSource]="dataSource" matSort class="full-width">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name.</th>
                <td mat-cell *matCellDef="let element"><small>{{element.name}}</small> </td>
            </ng-container>

            <ng-container matColumnDef="modified">
                <th mat-header-cell *matHeaderCellDef>Modified</th>
                <td mat-cell *matCellDef="let element"><small>{{element.modified}}</small></td>
            </ng-container>

            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef>City</th>
                <td mat-cell *matCellDef="let element"><small>{{element.city.name}}</small></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="open(row)"></tr>
        </table>
        <!-- <mat-paginator [length]="dataLength" (page)="pageChange($event)" pageSize="10"></mat-paginator> -->
    </div>
    <ng-template #loader>
        <div class='spinner-loader table-loader'>
            <mat-spinner class='center'></mat-spinner>
        </div>
    </ng-template>
</div>