<div class="container">
    <div class='bread-crumb'>
        <h2 class='bread-crumb-title'>Bookings Calendar</h2>
        <p><small>resources/bookings/calendar</small></p>
        <hr class='bread-crumb-divider'>
    </div>
    <ejs-schedule (eventClick)="onEventClick($event)" [hidden]="!(calendarData?.length > 0)" #scheduleObj height="70vh" rowAutoHeight="true" [currentView]="'month'" [views]="scheduleViews"> </ejs-schedule>`
    <div *ngIf="!(calendarData?.length > 0)">
        <div class='spinner-loader table-loader'>
            <mat-spinner class='center'></mat-spinner>
        </div>
    </div>
</div>