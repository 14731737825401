import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable, NgZone } from "@angular/core";
import { State } from "src/app/state/app-state.service";
import { AuthenticationService } from "src/app/services/core/authentication.service";
import { interval } from "rxjs";
import { delay } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private router: Router,
        private stateService: State,
        private ngZone : NgZone,
        private authentication : AuthenticationService
    ) { }

    canActivate() : Promise<boolean>{
        return new Promise<boolean>((resolve)=>{
            this.authentication.isAuthReady$
            .subscribe((status)=>{
                if(status === true) {
                    this.stateService.authentication$
                    .subscribe(auth =>{
                        this.ngZone.run(()=>{
                            if(auth){
                                resolve(true);
                            }
                            else { 
                                this.router.navigate(['account/login']);
                                resolve(false);
                            }
                        });
                    });               
                }
            })
        })
    }
}