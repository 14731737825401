import { Injectable } from '@angular/core';
import { auth, User } from 'firebase';
import { FirebaseService } from './firebase.service';
import { parseFirebaseError } from '../helpers/firebase/firebase.helpers';
import { EmailAuthCreds } from 'src/app/library/interfaces/general/EmailAuthCreds';
import { State } from 'src/app/state/app-state.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    public isAuthReady$ : BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private firebaseService: FirebaseService,
        private stateService: State
    ) { }


    public start(): Promise<void> {
        try {
            return new Promise<void>(async (resolve, reject) => {
                await this.listenForAuthStateChanges();
                resolve();
            })
        } catch (error) {

        }
    }

    public async listenForAuthStateChanges() {
        this.firebaseService.auth.onAuthStateChanged(async (user: User) => {
            this.stateService.authentication = user;
            this.isAuthReady$.next(true);
            Promise.resolve();
        });
    }

    public async emailSignIn(creds: EmailAuthCreds): Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            await this.firebaseService.auth.signInWithEmailAndPassword(creds.email, creds.password)
                .then((user: any) => {
                    resolve(user)
                })
                .catch((error) => {
                    reject(parseFirebaseError(error));
                })
        })
    }

    public async emailSignUp(creds: EmailAuthCreds): Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            await this.firebaseService.auth.createUserWithEmailAndPassword(creds.email, creds.password)
                .then((user) => {
                    resolve(user)
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    public async forgotPassword(email: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.firebaseService.auth.sendPasswordResetEmail(email)
                .then(() => {
                    resolve(true);
                }).catch(error => {
                    reject(error);
                });
        });
    }

    public async facebookAuthenticate(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.firebaseService.auth.signInWithRedirect(new auth.FacebookAuthProvider())
                .then(() => {
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                });
        })
    }

    public async googleAuthenticate(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.firebaseService.auth.signInWithRedirect(new auth.GoogleAuthProvider())
                .then(() => {
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                });
        })
    }

    public async logout(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.firebaseService.auth.signOut()
                .then(() => {
                    this.stateService.authentication = null;
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

}

