<mat-form-field appearance="outline" class="full-width">
  <mat-label>Search</mat-label>
  <input  matInput class="form-control">
  <mat-error >
    <span >Email is required</span>
  </mat-error>
</mat-form-field>
<table mat-table [dataSource]="source" class="mat-elevation-z8">
    <!-- Item Column -->
    <ng-container matColumnDef="item">
      <th mat-header-cell *matHeaderCellDef> Item </th>
      <td mat-cell *matCellDef="let transaction"> {{transaction.item}} </td>
      <td mat-footer-cell *matFooterCellDef> Total </td>
    </ng-container>
  
    <!-- Cost Column -->
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef> Cost </th>
      <td mat-cell *matCellDef="let transaction"> {{transaction.cost | currency}} </td>
      <td mat-footer-cell *matFooterCellDef> {{getTotalCost() | currency}} </td>
    </ng-container>
  
  
    <!-- The table will render two header rows, one data row per data object, and two footer rows. -->
    <tr mat-header-row *matHeaderRowDef="config?.displayedColumns"
        class="example-first-header-row">
    </tr>
  
    <tr mat-row *matRowDef="let row; columns: config?.displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="config?.displayedColumns" class="example-first-footer-row"></tr>
  </table>
  <mat-paginator [length]="100" [pageSize]="30"></mat-paginator>