import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CONFIG } from 'src/app/configuration/app-config';
import { LookupService } from 'src/app/services/other/lookup.service';
import { State } from 'src/app/state/app-state.service';


@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent{

  displayedColumns: string[] = ['id', 'createdOn', 'status', 'value'];
  dataSource : any = null;
  dataLength : number;

  constructor(public stateService : State, private router : Router, private lookUpService : LookupService) { }

  async ngOnInit() {

  }


  pageChange(event) {
    this.lookUpService.changeIndex(event.pageIndex + 1);
  }

  public open(row) { 

  }

}

