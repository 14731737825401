<mat-toolbar>
    <mat-toolbar-row class="base-toolbar-row">
        <div class="full-width toolbar-container">
            <div class="toolbar-right-content">
                <p class="user-toolbar-info">{{user?.username}}</p>
                <button [matMenuTriggerFor]="menu" mat-button>
                  <mat-icon class="account-icon">account_circle</mat-icon>
                  <mat-icon class="account-icon expand">expand_more</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="after">
                    <button (click)="accountService.logout();" mat-menu-item>
                      <mat-icon>logout</mat-icon>
                      <span>Logout</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>