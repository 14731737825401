import { Injectable } from "@angular/core";
import { CONFIG } from "src/app/configuration/app-config";
import { Order } from "src/app/library/interfaces/sales/Order";
import { OrderItem } from "src/app/library/interfaces/sales/OrderItem";
import { Payment } from "src/app/library/interfaces/sales/Payment";
import { Voucher } from "src/app/library/interfaces/sales/Voucher";
import { State } from "src/app/state/app-state.service";
import { HttpService } from "../networking/rest.service";

@Injectable({
    providedIn : 'root'
})


export class SaleService { 

   public orderItems : Array<any> = [];
   totalCost         : number = 0;

    constructor(private restService : HttpService, private stateService : State) { }

    public start() {
       this.getResources();
    }

    public addItemToOrder(item : any) : void { 
         let index = this.orderItems.findIndex((x)=> x.id === item.id);

         if(index >= 0) {
            this.orderItems[index].qty++;
            const qty = this.orderItems[index].qty;
            this.orderItems[index].cost = this.calculatePermitsPrice(qty,item);
         } else {
            this.orderItems.push({
               name     : item.name,
               cost     : item.baseCost,
               qty      : 1,
               id       : item.id
            })
         }
         this.totalCost = this.orderItems.reduce((prev,curr)=> prev +curr.cost,0);
    }

    public removePermitFromOrder(item) : void { 
      this.orderItems.splice(this.orderItems.findIndex((_item)=>{return _item.id == item.id}),1);
      this.totalCost = this.orderItems.reduce((prev,curr)=> prev +curr.cost,0);
    }

    private calculatePermitsPrice(count: number, permit) {
      let totalCost = 0;
      let selectedPermit = permit;
      let baseCost = selectedPermit.baseCost;
      if (count > selectedPermit.baseUsers) {
        if (selectedPermit.extraCost) {
          totalCost += baseCost;
          totalCost += (count - selectedPermit.baseUsers) * selectedPermit.extraCost;
        } else {
          totalCost += selectedPermit.baseUsers * baseCost;
          totalCost += (count - selectedPermit.baseUsers) * baseCost;
        }
      } else {
        if (count >= 1) {
          totalCost += baseCost;
        }
      }
      return totalCost;
    }

    public discardOrder() : void { 
       this.orderItems = [];
       this.totalCost = this.orderItems.reduce((prev,curr)=> prev +curr.cost,0);
    }

 
    public async getResources() { 
      try {
         this.getPermits();
         this.getMultidayHikes();
         this.loadAllCustomers();
      } catch (error) {
         
      }
    }

    private getMultidayHikes() { 
      let promises = [];
      let hikes  = [];

      this.stateService.permissions$.subscribe(async (sites)=>{
         if(sites){
            sites.forEach(site => {
               site.locations.forEach((location)=>{
                  promises.push(
                     this.restService.get(`${CONFIG.API.CORE.url}eco/activities?Search=activityCategory%20ne%201&Search=site%20eq%203&expand=true&OrderBy=name`).catch((error)=>{}));
               });
            });
            let results = await Promise.all(promises);
            results.forEach((batch)=>{
               if(batch){
                  hikes = hikes.concat(batch.value)
               }
            });

            this.stateService.saleHikingTrails = hikes;
         }
      });
    }

    private getPermits() { 
      let promises = [];
      let permits  = [];

      this.stateService.permissions$.subscribe(async (sites)=>{
         if(sites){
            sites.forEach(site => {
               site.locations.forEach((location)=>{
                  promises.push(
                     this.restService.get(`${CONFIG.API.CORE.url}eco/sites/${location.locationId}/permitTypes?OrderBy=name`).catch((error)=>{}));
               });
            });

            let results = await Promise.all(promises);

            results.forEach((batch)=>{
               if(batch){
                  permits = permits.concat(batch.value)
               }
            });

            this.stateService.saleItems = permits;
         }
      });
    }

    private async loadAllCustomers() { 
         this.stateService.customers = await this.restService.get(`${CONFIG.API.OLDAPI.url}eco/adventurers`);
    }
    public async searchCustomer(searchTerm : any) { 
       try {
         return this.stateService.customers.filter((customer)=> {
            if((customer.fullName).toLowerCase().indexOf(searchTerm) > 0 || (customer.emailAddress).toLowerCase().indexOf(searchTerm) > 0 || (customer.contactNo).toLowerCase().indexOf(searchTerm) > 0 ){
               return customer;
            }
         });
       } catch (error) {
          
       }
    }
}