import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/accounts.service';
import { UialertsService } from 'src/app/services/ui/ui-alerts.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public loading  : boolean = false; //form loading variable
  public hide: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: UialertsService,
    public accountService: AccountService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.loginForm = this.formBuilder.group({
      emailAddress: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required),
    });
  }

  async loginFormSubmit() {
    this.submitted = true;
    this.loading = true;


    if (this.loginForm.valid) {
      try {
        await this.accountService.emailSignIn({ email: this.loginForm.get('emailAddress').value, password: this.loginForm.get('password').value});
        
        this.router.navigate(['/']);
        // this.alertService.openSnackBar({ duration: 5, message: "You are now Logged In", mode: "success" });
      } catch (error) {
        this.loading = false;
        this.alertService.openSnackBar({ duration: 5, message: error, mode: "danger" });
      }
    } else {
      this.loading = false;
      return;
    }
  }

  get loginFormControls() { return this.loginForm.controls; }

}
