import { environment } from "src/environments/environment";

export namespace CONFIG {
    export namespace Firebase {
      export const firebaseEnvironment = environment.firebaseConfig
    }

    export namespace API {
      export namespace PORTAL {
        export const name       : string  = environment.API.PORTAL.name;
        export const production : boolean = environment.production;
        export const url        : string  = environment.API.PORTAL.url;
      }

      export namespace CORE {
        export const name       : string  = environment.API.CORE.name;
        export const production : boolean = environment.production;
        export const url        : string  = environment.API.CORE.url;
      }

      export namespace OLDAPI {
        export const name       : string  = environment.API.CORE.name;
        export const production : boolean = environment.production;
        export const url        : string  = environment.API.OLDAPI.url;
      }
    }
}