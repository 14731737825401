import { Injectable } from '@angular/core';
import { CONFIG } from 'src/app/configuration/app-config';
import { HttpService } from '../networking/rest.service';


@Injectable({
  providedIn: 'root'
})
export class BookingService {

    constructor(private httpService : HttpService) {  }

    public async getBookings( query : { 
        start?         : string,
        end?           : string,
        bookingNo?     : number;
        adventurer?    : string;
    }) : Promise<Array<any>> {        
        let results = await this.httpService.get(`${CONFIG.API.OLDAPI.url}eco/bookings?${query.start ? 'start='+ query.start : ''}${query.start ? '&end='+ query.end : ''}${query.bookingNo ? '&bookingNo='+ query.bookingNo : ''}${query.adventurer ? '&adventurer='+ query.adventurer : ''}`);
        return results;
    }

    public async getBooking(id : number) : Promise<any> { 
        let booking = await this.httpService.get(`${CONFIG.API.CORE.url}eco/bookings/${id}`);
        return booking;
    }

}