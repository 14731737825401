<div class="container">
  <div class="main-container">
    <img class="waiting center" src="../../../../assets/images/common/locked.svg">
    <div class="message center">
      <h5 class="mat-title center name" align="center">Locked</h5>
      <p class="mat-title center message-title">
        <strong><small>You don't have access to this resource</small></strong>
      </p>
    </div>
    <div class="message contact">
      <address align="center" class="mat-title center">
        <span style="margin-top: -15px;"> <small><strong>If you think you are seeing this by mistake contact support
              <br> <a href="mailto:webmaster@example.com">admin-support@migov.co.za</a></strong></small></span>

      </address>
    </div>
    <button class="i-button " routerLink="/home" type="submit" mat-button>Retry</button>
  </div>
</div>