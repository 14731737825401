<div class="table-search-wrapper">
    <form [formGroup]="form" class="">
        <mat-form-field appearance="outline">
            <mat-label>Filter By Search Term</mat-label>
            <input matInput class="form-control" formControlName="searchTerm">
        </mat-form-field>
        <!-- <mat-form-field appearance="outline">
            <mat-label>Filter By Status</mat-label>
            <mat-select multiple class="form-control" formControlName="statuses" placeholder="Select a Status">
                <mat-option *ngFor="let item of statusEnum | EnumToArrayPipe" [value]="item.index">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field> -->


    </form>
    <div class="search-reset-btn-wrapper">
        <button (click)="resetFilter()" mat-button>Reset Search Filters</button>
    </div>
</div>