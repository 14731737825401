import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { CONFIG } from "src/app/configuration/app-config";
import { ACTIVITY_TYPE } from "src/app/library/constants/activity";
import { DIFFICULTY } from "src/app/library/constants/difficulty";
import { BasicObject } from "src/app/library/interfaces/general/BasicObject";
import { HttpService } from "src/app/services/networking/rest.service";
import { UialertsService } from "src/app/services/ui/ui-alerts.service";

@Component({
    selector: 'update-activity-dialog',
    templateUrl: 'update-activity-dialog.html',
  })
  export class UpdateActivityDialog {
    
    form1 : FormGroup;

    activities : Array<BasicObject> = ACTIVITY_TYPE;
    difficulty_type : Array<BasicObject> = DIFFICULTY;

    loading : boolean = false;

    constructor(private formBuilder : FormBuilder, private http: HttpService, private uiService : UialertsService, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {

      this.form1 = this.formBuilder.group({
        name        : new FormControl(this.data.activity.name,[Validators.required]),
        description : new FormControl(this.data.activity.description,[Validators.required]),
        activity    : new FormControl(this.data.activity.activity,[Validators.required]),
        difficulty  : new FormControl(this.data.activity.difficulty,[Validators.required]),
        distanceKM      : new FormControl(this.data.activity.distance,[Validators.required]),
        elevationMeters : new FormControl(this.data.activity.elevation,[Validators.required]),
        durationMinutes : new FormControl(this.data.activity.hours,[Validators.required]),
        latitude        : new FormControl(this.data.activity.coordinates.lat,[Validators.required]),
        longitude       : new FormControl(this.data.activity.coordinates.lon,[Validators.required])
      });
    }

    public async updateActivity() { 
      try {

        this.loading = true;

        let formValue = this.form1.getRawValue();

        formValue['coordinates'] = {
          latitude  : formValue.latitude,
          longitude : formValue.longitude
        }

        // await this.http.post(`${CONFIG.API.ADMIN.url}/site/${this.data.siteId}/activity/${this.data.activity.id}`, formValue);

        this.uiService.openSnackBar({
          duration : 3,
          message : "Activity update",
          mode : "success"
        });

        this.loading = false;
      } catch (error) {
        this.uiService.openSnackBar({
          duration : 3,
          message : "Failed to update activity",
          mode : "danger"
        });

        this.loading = false;
      }
    }
  }
  