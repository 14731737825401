import { Pipe, PipeTransform } from '@angular/core';
import { NewsCategory } from '../../enums/global-enums';

@Pipe({
  name: 'NewsCategoryToFriendlyPipe'
})
export class NewsCategoryToFriendlyPipe implements PipeTransform {

  transform(value: NewsCategory, args?: any): any {
    return NewsCategory[value];
  }
}
