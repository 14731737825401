import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CONFIG } from 'src/app/configuration/app-config';
import { Site } from 'src/app/library/interfaces/general/Site';
import { SiteActivity } from 'src/app/library/interfaces/general/Site-Activity';
import { HttpService } from 'src/app/services/networking/rest.service';
import { UialertsService } from 'src/app/services/ui/ui-alerts.service';
import { CreateActivityDialog } from './create-activity-dialog/create-activity-dialog';
import { UpdateActivityDialog } from './update-activity-dialog/update-activity-dialog';

@Component({
  selector: 'app-site-edit',
  templateUrl: './site-edit.component.html',
  styleUrls: ['./site-edit.component.scss']
})
export class SiteEditComponent implements OnInit {

  site : Site;
  activities : Array<SiteActivity>;

  form1 : FormGroup;

  constructor(private http : HttpService, private route : ActivatedRoute, private formBuilder : FormBuilder, private snackBar : UialertsService, public dialog: MatDialog) { }

  async ngOnInit() {
    await this.loadResources();
    this.buildForms();
  }

  private async loadResources() { 
    this.activities = (await this.http.get(`${CONFIG.API.CORE.url}eco/sites/${this.route.snapshot.paramMap.get('id')}/activities`)).value;
    console.log(this.activities);
    
    this.site = await this.http.get(`${CONFIG.API.OLDAPI.url}eco/sites/${this.route.snapshot.paramMap.get('id')}`);

    console.log(this.site);
    

    // console.log(this.activities);
    
  }

  private buildForms() : void { 
    this.form1 = this.formBuilder.group({
      name        : new FormControl(this.site.name,[Validators.required]),
      description : new FormControl(this.site.description,[Validators.required]),
      latitude    : new FormControl(this.site.coordinates.latitude,[Validators.required]),
      longitude   : new FormControl(this.site.coordinates.longitude,[Validators.required])
    })
  }


  public async updateSite() {
    try {
      let update = this.form1.getRawValue(); 

      update['coordinates'] = {
        latitude : update.latitude,
        longitude: update.longitude
      }

      
      delete update.latitude;
      delete update.longitude;


      // await this.http.post(`${CONFIG.API.ADMIN.url}/site/${this.route.snapshot.paramMap.get('id')}`,update);
      
      this.snackBar.openSnackBar({
        duration : 3,
        message  : "Update complete",
        mode     : 'success'
      });
    } catch (error) {
      this.snackBar.openSnackBar({
        duration : 3,
        message  : "Update failed",
        mode     : "danger"});
        
        console.error(error);
    }
  }

  public createActivity() {
    const dialogRef = this.dialog.open(CreateActivityDialog,{
      data : {
        siteId : this.route.snapshot.paramMap.get('id')
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadResources();
    });
  }
  
  public updateActivity(activity) {
    const dialogRef = this.dialog.open(UpdateActivityDialog,{
      data : {
        siteId : this.route.snapshot.paramMap.get('id'),
        activity : activity
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadResources();
    });
  }

}
