import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable, NgZone } from "@angular/core";
import { State } from "src/app/state/app-state.service";
import { AuthenticationService } from "src/app/services/core/authentication.service";
import { debounce, skip, skipWhile } from "rxjs/operators";
import { interval } from "rxjs";

@Injectable({ providedIn: 'root' })
export class NotAuthenticatedGuard implements CanActivate {
    constructor(
        private router: Router,
        private stateService: State,
        private ngZone : NgZone,
        private authentication : AuthenticationService
    ) { }

    canActivate() : Promise<boolean>{
        return new Promise<boolean>((resolve)=>{
            this.stateService.authentication$
            .subscribe(auth =>{
                this.ngZone.run(()=>{
                    if(auth){
                        this.router.navigate(['/']);
                        resolve(false);
                    }
                    else { 
                        resolve(true);
                    }
              });
            });
        })
    }
}