export enum SnapscanStatusType {
    Pending,
    Completed,
    Error
}
export enum EmailParamType {
    OrderId,
    OrderItemAdventurerId,
    OrderItemId,
    UserId,
    AdventurerId,
    RefundId,
    PublicContactObject
}

export enum NewsCategory{
    Ecotourism,
    Forestry,
    Adventure,
}

export enum PublicContactType {
    AnonymousTipoff,
    Complaint,
    IssueInApp,
    ReportSuspiciousBehaviour,
    ITSupport,
    General,
}
export enum ZapperStatusType {
    Success = 1,
    Failed,
    Error
}
export enum PaymentType {
    Ecentric,
    Cash,
    CreditCard,
    Snapscan,
    Zapper
}

export enum DayOfWeek {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

export enum UIAlertType{
    warning = 'warning',
    info = 'info',
    error = 'danger',
    success = 'success'
}

export enum DifficultyType {
    Easy,
    Moderate,
    Hard,
    Extreme,
    Nightmare
}
export enum ActivityType {
    Hiking,
    Swimming,
    Climbing,
    Birding,
    Events,
    MTB,
    Accomodation,
    Running,
    Fishing
}

export enum FeedbackType {
    AnonymousTipoff,
    Complaint,
    IssueInApp,
    ReportSuspiciousBehaviour,
    ITSupport,
    General,
}

export enum MediaType {
    Image,
    Video
}

export enum EntityType {
    SiteActivity,
    Product,
    Site,
    ProductResource,
    Resource,
    Payment,
    Order,
    OrderItem
}

export enum StatusType {
    Draft,
    AwaitingApproval,
    Deleted,
    Disabled,
    Active,
    Expired,
    Active_Portal,
    Completed
}


export enum ProductType {
    Hike,
    Permit,
    Extension,
    Accomodation,
    Addon,
    Event,
    Merchandise
}

export enum RedeemableType {
    Voucher
}
export enum DiscountType {
    Percentage,
    Value
}

export enum EmailType {
    AdventurerCreation,
    AdventurerVerification,
    AdventurerPasswordReset,
    UserPasswordReset,
    UserCreation,
    Invoice,
    PermitExpiry,
    PermitExpiry30Days,
    DeferredPayment,
    RefundRequestedAdventurer,
    RefundRequestedAdmin,
    RefundApproved,
    RefundDeclined,
    ContactPublicAdmin
}
export enum SortDirectionType {
    ASC,
    DESC
}