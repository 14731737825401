<div class="container">
    <div class='bread-crumb'>
        <h2 class='bread-crumb-title'>Sites</h2>
        <p><small>resources/sites/create</small></p>
        <hr class='bread-crumb-divider'>
    </div>

    <div *ngIf="!componentLoading; else loader" class="standard-card tm-30">
        <form [formGroup]="form1" class="row full-width">
            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput class="form-control" formControlName="name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput class="form-control" formControlName="description">
                </mat-form-field>
            </div>


            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Ecotourism site</mat-label>
                    <mat-select formControlName="ecotourism" class="form-control">
                        <mat-option [value]="true">
                            <small>Yes</small>
                        </mat-option>
                        <mat-option [value]="false">
                            <small>No</small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3 class="mat-title">Location</h3>
            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Latitude</mat-label>
                    <input matInput class="form-control" type="number" formControlName="latitude">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Longitude</mat-label>
                    <input matInput class="form-control" type="number" formControlName="longitude">
                </mat-form-field>
            </div>

            <button class="standard-button" [disabled]="form1?.invalid" (click)="createSite();" mat-button><small>Create site</small></button>
        </form>
    </div>
    <ng-template #loader>
        <div class='spinner-loader table-loader'>
            <mat-spinner class='center'></mat-spinner>
        </div>
    </ng-template>
</div>