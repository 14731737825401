import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek } from '../../enums/global-enums';

@Pipe({
  name: 'ActivityTypePipe'
})
export class ActivityTypePipe implements PipeTransform {

  transform(value: number, args?: any): any {

    console.log(value);
    
    let activityTYpe : string

    switch (value) {
        case 0:
            activityTYpe = "Hiking"
            break;      
        
        case 1:
            activityTYpe = "Swimming"
            break;
        
        case 2:
            activityTYpe = "Climbing"
            break;

        case 3:
            activityTYpe = "Birding"
            break;

        case 4:
            activityTYpe = "Events"
            break;

        case 5:
            activityTYpe = "MTB"
            break;
        
        case 6:
            activityTYpe = "Accomodation"
            break;

        case 7:
            activityTYpe = "Running"
            break;

        case 8:
            activityTYpe = "Fishing"
            break;
    
        default:
            break;
    }
      return activityTYpe;
  }
}
