import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { FirebaseService } from "../core/firebase.service";
import { SearchFilter } from 'src/app/library/interfaces/general/SearchFilter';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  params: new HttpParams(),
  responseType: 'json' as any,
};

@Injectable({
  providedIn: "root"
})
export class HttpService {
  constructor(
    private httpClient: HttpClient,
    private firebaseService: FirebaseService
  ) { }

  public async post(path: string, data: any, options?: any): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.firebaseService.getToken());
    httpOptions.responseType = options ? options.responseType : httpOptions.responseType;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(`${path}`, data, httpOptions)
        .subscribe(
          results => {
            resolve(results);
          }
          , error => {
            reject(error);
          })
    })
  }

  public async patch(path: string, data: any, options?: any): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.firebaseService.getToken());
    httpOptions.responseType = options ? options.responseType : httpOptions.responseType;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(`${path}`, data, httpOptions)
        .subscribe(
          results => {
            resolve(results);
          }
          , error => {
            reject(error);
          })
    })
  }


  public async get(path: string, searchFilter?: SearchFilter): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.firebaseService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.get(`${await this.stringifyFilterQueryParams(path, searchFilter)}`, httpOptions)
        .subscribe(
          results => {
            resolve(results)
          }
          , error => {
            reject(error);
          })
    })
  }

  public async get$(path: string, params?: any) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.firebaseService.getToken());
    return this.httpClient.get(`${path}`, httpOptions);
  }

  public async delete(path: string): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.firebaseService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.delete(`${path}`, httpOptions)
        .subscribe(
          results => resolve(results)
          , error => {
            reject(`get error occured - ${error}`);
          })
    })
  }

  public async getExternal(path: string): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.firebaseService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.get(`${path}`, httpOptions)
        .subscribe(
          results => resolve(results)
          , error => {
            reject(`get error occured - ${error}`);
          })
    })
  }

  public async put(path: string, body?: any): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.firebaseService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.put(`${path}`, body, httpOptions)
        .subscribe(
          results => resolve(results)
          , error => {
            reject(`put error occured - ${error}`);
          })
    })
  }

  private async stringifyFilterQueryParams(url: string, filter: SearchFilter): Promise<string> {
    if (filter) {
      let firstParam = !url.includes('?') ? '?' : '&';
      let filterString = Object.entries(filter).map(([key, val]) => {
        if (Array.isArray(val)) {
          return val.map((value, index, array) => `${key}=${value}`).join('&');
        }
        return val ? `${key}=${val}` : '';
      }).join('&')

      return url.concat(firstParam, filterString);
    } else {
      return url;
    }
  }
}
