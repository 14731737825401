import { Route } from "@angular/router";
import { BaseLayoutComponent } from "src/app/components/layouts/base-layout/base-layout.component";
import { BookingdetailsComponent } from "src/app/components/pages/admin/bookings/bookingdetails/bookingdetails.component";
import { BookingsComponent } from "src/app/components/pages/admin/bookings/bookings/bookings.component";
import { CalendarComponent } from "src/app/components/pages/admin/bookings/calendar/calendar.component";
import { SiteCreateComponent } from "src/app/components/pages/admin/sites/site-create/site-create.component";
import { SiteEditComponent } from "src/app/components/pages/admin/sites/site-edit/site-edit.component";
import { SitesComponent } from "src/app/components/pages/admin/sites/sites.component";
import { DashboardComponent } from "src/app/components/pages/dashboard/dashboard.component";
import { LoginComponent } from "src/app/components/pages/login/login.component";
import { NoAccessComponent } from "src/app/components/pages/no-access/no-access.component";
import { SaleComponent } from "src/app/components/pages/sales/sale/sale.component";
import { AuthenticatedGuard } from "./guards/authenticated";
import { NotAuthenticatedGuard } from "./guards/not-authenticated";
export const ROUTES: Route[] = [
    {
        path: "",
        component: BaseLayoutComponent,
        canActivate: [AuthenticatedGuard],
        children: [
            { path: "", redirectTo: "dashboard", pathMatch: 'full' },
            { path: "dashboard", component: DashboardComponent },

            // { path: "customers", component: CustomersComponent },
            // { path: "customers/update/:uid", component: CustomerComponent },


            // { path: "manage/users", component: UsersComponent },
            // { path: "manage/users/create", component: CreateUserComponent },
            // { path: "manage/users/update/:uid", component: UpdateUserComponent },

            // { path: "manage/resources", component: ResourcesComponent },
            // { path: "manage/resources/create", component: ResourceCreateComponent },
            // {
            //     path: "manage/resources/update/:id", component: ResourceEditComponent, children: [
            //         { path: "", component: ResourceDetailsEditComponent },
            //         { path: "capacities", component: ResourceCapacitiesComponent },
            //     ]
            // },

            { path: "frontdesk", component: SaleComponent },
            // { path: "frontdesk/booking", component: BookingSaleComponent },


            { path: "bookings", component: BookingsComponent },
            { path: "bookings/calendar", component: CalendarComponent },
            { path: "bookings/:bookingNo", component: BookingdetailsComponent },

            { path: "resources/sites", component: SitesComponent },
            { path: "resources/sites/create", component: SiteCreateComponent },
            { path: "resources/sites/update/:id", component: SiteEditComponent },


        ]
    },
    { path: 'account/no-access', component: NoAccessComponent },
    { path: "account/login", component: LoginComponent, canActivate: [NotAuthenticatedGuard] }
]