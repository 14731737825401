import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventClickArg } from '@fullcalendar/angular';
import { AgendaService,MonthAgendaService, MonthService, ScheduleComponent, TimelineMonthService, TimelineViewsService, View} from '@syncfusion/ej2-angular-schedule';
import * as moment from 'moment';
import { BookingService } from 'src/app/services/resources/bookings.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService]
})
export class CalendarComponent {

  @ViewChild("scheduleObj") public scheduleObj: ScheduleComponent;

  calendarData; 
  
  public scheduleViews: View[] = ['Month', 'Week', 'WorkWeek', 'Day'];

  constructor(private bookingService : BookingService, private router : Router) { }

  ngAfterViewInit(){ this.loadData() }

  private async loadData() {
    this.calendarData = await this.bookingService.getBookings({
      start       : moment(new Date()).subtract(31,'days').toISOString(),
      end         : moment(new Date()).add(30,'days').toISOString()
    });
    this.scheduleObj.addEvent(this.serializeData(this.calendarData));
  }

  private serializeData(data : Array<any>) {
    data = data.filter((item)=> item.bookingStatus.id == 5); //only show active items
    return data.map((item) => {
      return  { 
        Id          : item.id,
        Subject     : `${item.adventurer.fullName} - ${item.id}`,
        StartTime   : new Date(item.startDate),
        EndTime     : new Date(item.endDate),
        isReadOnly  : 'Yes'
      }
    });
  }

  onEventClick(args : any) : void {
    console.log(args);
    this.router.navigate([`bookings/${args.event.Id}`])
    
  }
  
}
