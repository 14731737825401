import { Component, OnInit } from '@angular/core';
import { SaleService } from 'src/app/services/sales/sale.service';
import { State } from 'src/app/state/app-state.service';

@Component({
  selector: 'app-leftpane',
  templateUrl: './leftpane.component.html',
  styleUrls: ['./leftpane.component.scss']
})
export class LeftpaneComponent implements OnInit {

  
  saleItems: any;
  constructor(public saleService : SaleService, public stateService : State) { }

  async ngOnInit() {

    this.stateService.saleItems$.subscribe((results)=>{
      this.saleItems = results;
    })
    
  }
  

}
