import { BasicObject } from "../interfaces/general/BasicObject";

export const DIFFICULTY: Array<BasicObject> = [
    {
        name : "Easy",
        id   : 0
    },
    {
        name : "Moderate",
        id   : 1
    },
    {
        name : "Hard",
        id   : 2
    },
    {
        name : "Extreme",
        id   : 3
    }
]
