<mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened>
        <img class="logo" src="../../../../assets/images/logos/white-logo.svg" />
        <div class="sidebar-buttons">
            <p class="category-title">Sales</p>
            <button class="side-bar-button" type="button" mat-button full routerLink="frontdesk" [routerLinkActive]="['sidebar-active']">
                    <mat-icon style="color:#6DC988" aria-hidden="false" aria-label="Example home icon">point_of_sale</mat-icon>
                    <span class="name">Front Desk</span>
            </button>

            <p class="category-title">Operations</p>
            <button (click)="resourcesExpanded = !resourcesExpanded" class="side-bar-button" type="button" mat-button full [routerLinkActive]="['sidebar-active']">
                <mat-icon style="color:#FCC300" aria-hidden="false" aria-label="Example home icon">wysiwyg</mat-icon>
                <span class="name">Resources</span>
                <mat-icon [hidden]="!resourcesExpanded" class="drow-down" aria-hidden="false" aria-label="Example home icon">expand_more</mat-icon>
                <mat-icon [hidden]="resourcesExpanded" class="drow-down" aria-hidden="false" aria-label="Example home icon">expand_less</mat-icon>
            </button>

            <div [hidden]="resourcesExpanded" class="expandable">
                <button class="side-bar-button" type="button" mat-button full routerLink="bookings" [routerLinkActive]="['sidebar-active']">
                    <span>Bookings</span>
                </button>
                <button class="side-bar-button" type="button" mat-button full routerLink="resources/sites" [routerLinkActive]="['sidebar-active']">
                    <span>Sites</span>
                </button>
            </div>
        </div>

    </mat-drawer>
    <mat-drawer-content>
        <app-toolbar></app-toolbar>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>