import { Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Pager } from 'src/app/library/interfaces/general/Pager';
import { SearchFilter } from 'src/app/library/interfaces/general/SearchFilter';
import { HttpService } from '../networking/rest.service';


@Injectable({
    providedIn : "root"
})

export class LookupService {

    batch   : BehaviorSubject<{data : Array<any>, size : number}> = new BehaviorSubject<{data : Array<any>, size : number}>(null);
    pager   : Pager;

    constructor(private httpService : HttpService) { }

    public async set(pager : Pager, clearData? : boolean) : Promise<any> {
        if(clearData) {
            this.batch.next(null);
        }
        this.pager = pager;
        return this.getData();
    }

    public async search(searchTerm : string, statuses? : Array<number>) : Promise<any> {
        this.pager.filter.pageNumber = 1;
        this.pager.filter.searchTerm = searchTerm;
        this.pager.filter.statuses = statuses ? statuses : null;
        
        return this.getData();
    }

    public async next() {
        this.pager.filter.pageNumber++;
        return this.getData();
    }

    public async back() { 
        this.pager.filter.pageNumber--;
        return this.getData();
    }

    public async changeIndex(index : number) { 
        this.pager.filter.pageNumber = index;
        return this.getData();
    }

    public async getData() { 
        let response    = await this.httpService.get(this.pager.resourceUrl,this.pager.filter);
        this.batch.next({ data : response.data, size : response.collectionSize});
        Promise.resolve();
    }
    
}
