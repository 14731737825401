import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek } from '../../enums/global-enums';

@Pipe({
  name: 'DayOfWeekToFriendlyPipe'
})
export class DayOfWeekToFriendlyPipe implements PipeTransform {

  transform(value: number, args?: any): any {
      return DayOfWeek[value];
  }

}
