import { Injectable } from "@angular/core";
import { EmailAuthCreds } from "src/app/library/interfaces/general/EmailAuthCreds";
import { State } from "src/app/state/app-state.service";
import { AuthenticationService } from "../core/authentication.service";

@Injectable({
    providedIn: 'root'
})

export class AccountService {

    constructor(
        private auth         : AuthenticationService,
        private stateService : State
    ) { }

    public async emailSignIn(creds : EmailAuthCreds) : Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>(async (resolve,reject)=>{
            try {
                const user = await this.auth.emailSignIn(creds);
                resolve(user);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async resetPassword(email : string) : Promise<boolean> {
        return new Promise<boolean>(async (resolve,reject)=>{
            try {
                const status = await this.auth.forgotPassword(email);
                resolve(status);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async googleLinkAccount() {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const status = await this.auth.googleAuthenticate();
                resolve(status);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async facebookLinkAccount() {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const status = await this.auth.facebookAuthenticate();
                resolve(status);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async logout(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const status = await this.auth.logout();
                resolve(status);
            }
            catch (error) {
                reject(Error(error));
            }
        })
    }
}