import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { BookingService } from 'src/app/services/resources/bookings.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource : any;
  displayedColumns : Array<string> = ['id','fullName','activity','startDate','days','pax','status'];


  bookings  : Array<any>;

  start     : FormControl = new FormControl((new Date()).toISOString());
  end       : FormControl = new FormControl(moment(new Date()).add(2,'months').toISOString());
  bookingNo : FormControl = new FormControl();
  adventurer: FormControl = new FormControl();
  
  constructor(private bookingService : BookingService) { }

  async ngOnInit() {
    this.bookings = await this.bookingService.getBookings({
      start : this.start.value,
      end   : this.end.value
    });

    this.dataSource = new MatTableDataSource(this.bookings);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator
    }, 1000);
  }


  public async loadBookings() {
    this.bookings = await this.bookingService.getBookings({
      start       : (new Date(this.start.value)).toISOString(),
      end         : (new Date(this.end.value)).toISOString(),
      bookingNo   : this.bookingNo.value,
      adventurer  : this.adventurer.value
    });

    this.dataSource = new MatTableDataSource(this.bookings);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator
    }, 1000);
    
  }

}
