import { Component, OnInit } from '@angular/core';
import { User } from 'firebase';
import { BehaviorSubject } from 'rxjs';
import { IUser } from 'src/app/library/interfaces/account/User';
import { AccountService } from 'src/app/services/account/accounts.service';
import { SideNavService } from 'src/app/services/helpers/side-nav.service';
import { PathService } from 'src/app/services/other/path.service';
import { State } from 'src/app/state/app-state.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  path : BehaviorSubject<string>;
  user : IUser;

  constructor(
    public sideNavService : SideNavService, 
    public stateService   : State,
    public pathService    : PathService,  
    public accountService : AccountService) { }

  ngOnInit() {
    this.path = this.pathService.get();
    
    this.path.subscribe((value)=>{
      console.log(value);
    });
    

    this.stateService.user$
    .subscribe((user)=>{
      this.user = user;
    })

  }

}
