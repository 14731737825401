import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { CONFIG } from "src/app/configuration/app-config";

@NgModule({
    imports: [
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireModule.initializeApp(CONFIG.Firebase.firebaseEnvironment.firebase)
    ],
    exports: [
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
    ]
})

export class FirebaseModule{}