import { AccountService } from "./account/accounts.service";
import { AuthenticationService } from "./core/authentication.service";
import { FirebaseService } from "./core/firebase.service";
import { IconRegistry } from "./helpers/icons-registry.service";
import { SideNavService } from "./helpers/side-nav.service";
import { BookingService } from "./resources/bookings.service";
import { ResourcesService } from "./resources/resources.service";
// import { CustomerService } from "./sales/customers.services";
// import { ProductsService } from "./sales/products.service";
// import { SaleService } from "./sales/sale.service";
import { UialertsService } from "./ui/ui-alerts.service";
// import { SiteService } from './resources/site.service';
// import { ProductResourceService } from './resources/product-resource.service';
// import { ResourceService } from './resources/resource.service';
// import { NewsService } from "./resources/news.service";
// import { PathService } from "./other/path.service";
// import { LookupService } from "./other/lookup.service";


export const SERVICES = [

   IconRegistry,

   //Account
   AccountService,

   //Core
   AuthenticationService,
   FirebaseService,

   //User Interface
   UialertsService,
   SideNavService,

   ResourcesService,
   BookingService

   //Other
   // LookupService,
   // PathService,

   //Sales
   // ProductsService,
   // CustomerService,
   // SaleService,

   //resources
   // SiteService,
   // ProductResourceService,
   // ResourceService,
   // NewsService

]