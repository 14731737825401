<section class="top-section">
    <div class="full-width search-container">
        <mat-progress-bar *ngIf="(stateService.customers$ | async) == null" mode="query"></mat-progress-bar>
        <mat-form-field appearance="fill">
            <mat-label>Add Customer Order</mat-label>
            <!-- <mat-autocomplete [disabled]="(stateService.customers$ | async) == null" #auto="matAutocomplete">
                <mat-option *ngFor="let option of options" [value]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete> -->

            <input (input)="saleService.searchCustomer($event)" matInput>
        </mat-form-field>
    </div>
    <div class="invoice">
        <div class="invoice-header">
            <h6 class="item-name">Item</h6>
            <h6 class="item-qty">Qty</h6>
            <h6 class="item-price">Price</h6>
        </div>
        <div class="no-items" *ngIf="saleService.orderItems.length == 0">
            <h5>NO ITEMS CURRENTLY IN ORDER</h5>
        </div>
        <div class="item-list">
            <mat-list role="list">
                <mat-list-item *ngFor="let item of saleService.orderItems" role="listitem">
                    <div class="item-name">
                        <mat-icon (click)="saleService.removePermitFromOrder(item)" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                        <span>{{item.name}}</span>
                    </div>
                    <span class="item-qty">X {{item.qty}}</span>
                    <span class="item-price mat-caption"><strong>R{{item.cost}}</strong></span>
                </mat-list-item>
            </mat-list>
        </div>

    </div>
</section>
<h4 class="total-due mat-title">AMOUNT DUE : <span class="rands-value">R{{saleService.totalCost}}</span></h4>
<section class="bottom-section">
    <mat-form-field class="payment-method" appearance="outline">
        <mat-label>Payment Method</mat-label>
        <mat-select>
            <mat-option>
                Zapper
            </mat-option>
            <mat-option>
                Snapscan
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="amount" appearance="outline">
        <mat-label>Amount(R)</mat-label>
        <input matInput>
    </mat-form-field>
    <button class="full-width checkout" mat-button>COMPLETE PAYMENT</button>
</section>