import { Component, OnInit, Input } from '@angular/core';
import { Crumb, BreadCrumb } from 'src/app/library/interfaces/general/Crumb';
import { Router } from '@angular/router';
import { State } from 'src/app/state/app-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  public breadCrumb$: Observable<BreadCrumb>;
  constructor(private router: Router, private state: State) { }

  ngOnInit() {
    this.breadCrumb$ = this.state.breadCrumb$;
  }

  public async navigate(link: string) {
    this.router.navigateByUrl(link);
  }
}
