import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "firebase";
import { State } from "src/app/state/app-state.service";
import { HttpService } from "../networking/rest.service";
import { IUser } from "src/app/library/interfaces/account/User";
import { CONFIG } from "src/app/configuration/app-config";


@Injectable({
    providedIn: 'root'
})

export class ProfileService {

    constructor(
        private restService: HttpService,
        private stateService: State,
        private ngZone: NgZone
    ) { }


    public start(): void {
        this.monitorAuthentication();
    }


    public async createProfile(data: any): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {

            } catch (error) {
                console.error(error);
            }
        });
    }

    public async updateProfile(uid: string, user: IUser): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
            } catch (error) {
                console.error(error);
            }
        });
    }

    public async getPermissions(uid: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                let userDetails  = await this.restService.get(`${CONFIG.API.PORTAL.url}admin/permissions/${uid}`);
                let permissions = await this.restService.get(`${CONFIG.API.CORE.url}admin/auth/accessdata/moduleaccess`);
                
                this.stateService.user = userDetails.user;
                this.stateService.permissions = permissions.moduleAccess
                
                resolve(true);
            } catch (error) {
                console.error(error);
            }
        });
    }

    public monitorAuthentication(): void {
        try {
            this.stateService.authentication$
                .subscribe(async (authenticaion: User) => {
                    if (authenticaion) {
                        await this.getPermissions(authenticaion.uid)
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }
}