import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingService } from 'src/app/services/resources/bookings.service';

@Component({
  selector: 'app-bookingdetails',
  templateUrl: './bookingdetails.component.html',
  styleUrls: ['./bookingdetails.component.scss']
})
export class BookingdetailsComponent implements OnInit {  

  bookingId; 

  constructor(private route : ActivatedRoute, private bookingService : BookingService) {
    this.bookingId = this.route.snapshot.params.bookingNo
  }

  ngOnInit(): void {
  }

  private load

}
