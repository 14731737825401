import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { UialertsService } from 'src/app/services/ui/ui-alerts.service';
import { Router } from '@angular/router';
import { StatusType } from "src/app/library/enums/global-enums";
import { SearchEvent } from 'src/app/library/interfaces/general/SearchEvent';

@Component({
  selector: 'table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit {

  public hide: boolean = true;
  public form: FormGroup;
  public submitted: boolean = false;

  public statusEnum = StatusType;
  @Input() defaultStatuses: StatusType[];
  @Output() searchEvent = new EventEmitter<SearchEvent>();

  constructor(private formBuilder: FormBuilder,
    private alertService: UialertsService, private router: Router) { }


  async ngOnInit() {
    await this.createForm();
    this.formListeners();
  }

  public clearControl(control: string) {
    this.form.get(control).setValue(null);
  }

  public async resetFilter() {
    this.form.reset({ searchTerm: null, statuses: this.defaultStatuses ? this.defaultStatuses : [StatusType.Active]});
  }
  private async createForm() {
    this.form = this.formBuilder.group({
      searchTerm: new FormControl(null),
      statuses: new FormControl(this.defaultStatuses ? this.defaultStatuses : [StatusType.Active])
    });
  }

  private async formListeners() {
    this.form.valueChanges.pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(async (form) => {
        this.searchEvent.emit(form);
      });
  }


  get fc() { return this.form.controls; }

}
