import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavService } from 'src/app/services/helpers/side-nav.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit, AfterViewInit {

  @ViewChild("sidenav") sidenav: MatSidenav;

  resourcesExpanded : boolean = true;

  constructor(public sideNavService: SideNavService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.sideNavService.setSidenav(this.sidenav);
  }
}
