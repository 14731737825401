import { BasicObject } from "../interfaces/general/BasicObject";

export const ACTIVITY_TYPE: Array<BasicObject> = [
    {
        name : "Hiking",
        id   : 0
    },
    {
        name : "Swimming",
        id   : 1
    },
    {
        name : "Climbing",
        id   : 2
    },
    {
        name : "Birding",
        id   : 3
    },
    {
        name : "Events",
        id   : 4
    },
    {
        name : "MTB",
        id   : 5
    },
    {
        name : "Accomodation",
        id   : 6
    },
    {
        name : "Running",
        id   : 7
    },
    {
        name : "Fishing",
        id   : 8
    }
]
    
