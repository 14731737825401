import { Injectable } from '@angular/core';
import { UserInfo } from 'firebase';
import { BehaviorSubject, Observable } from 'rxjs';
import { auth, User } from 'firebase';
import { IUser } from '../library/interfaces/account/User';
import { Product } from '../library/interfaces/sales/Product';
import { Adventurer } from '../library/interfaces/sales/Adventurer';
import { Crumb, BreadCrumb } from '../library/interfaces/general/Crumb';
import { Site } from '../library/interfaces/general/Site';

@Injectable({
  providedIn: 'root'
})
export class State {

  private readonly _user = new BehaviorSubject<IUser>(null as IUser);
  private readonly _permissions = new BehaviorSubject<IUser>(null as any);
  private readonly _authentication = new BehaviorSubject<User>(null);
  private readonly _authToken = new BehaviorSubject<string>(null);
  private readonly _appLoading = new BehaviorSubject<boolean>(true);
  private readonly _breadCrumb = new BehaviorSubject<BreadCrumb>(null);


  //sales 

  private readonly _allSaleItems = new BehaviorSubject<any>(null as any);
  private readonly _hikingTrails = new BehaviorSubject<any>(null as any);
  private readonly _customers = new BehaviorSubject<any>(null as any);


  private readonly _sites = new BehaviorSubject<Array<Site>>(null);
  private readonly _adventurers = new BehaviorSubject<Array<Adventurer>>(null);

  get authToken$(): Observable<string> {
    return this._authToken.asObservable();
  }
  set authToken(token: string) {
    this._authToken.next(token);
  }

  get user$(): Observable<IUser> {
    return this._user.asObservable();
  }
  set user(user: IUser) {
    this._user.next(user);
  }


  get saleItems$(): Observable<any> {
    return this._allSaleItems.asObservable();
  }

  get saleItems(): any{
    return this._allSaleItems.value;
  }
  set saleItems(value: any) {
    this._allSaleItems.next(value);
  }

  get saleHikingTrails$(): Observable<any> {
    return this._hikingTrails.asObservable();
  }

  get saleHikingTrails(): any{
    return this._hikingTrails.value;
  }
  set saleHikingTrails(value: any) {
    this._hikingTrails.next(value);
  }


  get customers$(): Observable<any> {
    return this._customers.asObservable();
  }

  get customers(): any{
    return this._customers.value;
  }
  set customers(value: any) {
    this._customers.next(value);
  }


  
  get permissions$(): Observable<any> {
    return this._permissions.asObservable();
  }

  get permissions(): any {
    return this._permissions.value;
  }
  set permissions(value: any) {
    this._permissions.next(value);
  }

  set breadCrumb(obj: BreadCrumb) {
    this._breadCrumb.next(obj);
  }

  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

  get authentication$(): Observable<UserInfo> {
    return this._authentication.asObservable();
  }
  get authentication(): User {
    return this._authentication.value;
  }
  set authentication(authentication: User) {
    this._authentication.next(authentication);
  }

  get appLoading$(): Observable<boolean> {
    return this._appLoading.asObservable();
  }
  get appLoading(): boolean {
    return this._appLoading.value;
  }
  set appLoading(authentication: boolean) {
    this._appLoading.next(authentication);
  }


  get sites$(): Observable<Array<Site>> {
    return this._sites.asObservable();
  }

  get sites(): Array<Site> {
    return this._sites.value
  }
  set sites(sites: Array<Site>) {
    this._sites.next(sites);
  }


  get adventurers$(): Observable<Array<Adventurer>> {
    return this._adventurers.asObservable();
  }

  get adventurers(): Array<Adventurer> {
    return this._adventurers.value
  }
  set adventurers(products: Array<Adventurer>) {
    this._adventurers.next(products);
  }



}
