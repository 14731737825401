import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  name : "Development",
  production: false,
  firebaseConfig  : {
    production: false,
    firebase: {
      apiKey: "AIzaSyBK07eLq53UFX6UiOWENV61u-8H1HllNaI",
      authDomain: "myportal-982dd.firebaseapp.com",
      databaseURL: "https://myportal-982dd.firebaseio.com",
      projectId: "myportal-982dd",
      storageBucket: "myportal-982dd.appspot.com",
      messagingSenderId: "318322754275",
      appId: "1:318322754275:web:c628421aa1e203ccbefbd6"
    }
  },
  API : {
    PORTAL : {
      name : "portal api",
      url  : "https://papi.mto.group/"
    },
    CORE : {
      name : "core api",
      url  : "https://core.mto.group/"
    },
    OLDAPI : {
      name : "old api",
      url  : "https://api.mto.group/"
    }
  }
};
