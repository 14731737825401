import { Component, OnInit } from '@angular/core';
import { SaleService } from 'src/app/services/sales/sale.service';
import { State } from 'src/app/state/app-state.service';

@Component({
  selector: 'app-rightpane',
  templateUrl: './rightpane.component.html',
  styleUrls: ['./rightpane.component.scss']
})
export class RightpaneComponent implements OnInit {
  saleItems: any;
  saleItemsMAIN: Array<any>;

  permits : Array<any>;
  permitsMain : Array<any>;
  hikingTrails : Array<any>;
  hikingTrailsMain : Array<any>;
  saleType : string = 'Hiking Trails';

  searchTerm : string;
  constructor(public salesService : SaleService, private stateService : State) { }

  async ngOnInit() {
    this.stateService.saleItems$.subscribe((results)=>{
      this.permits = results;
      this.permitsMain = results;
    });

    this.stateService.saleHikingTrails$.subscribe((results)=>{
      this.hikingTrails = results;
      this.hikingTrailsMain = results;
    });
  }
  public changeSaleType(saleType) : void { 
    this.saleType = saleType;
    this.searchTerm = null;
    
    this.saleType == 'Hiking Trails' ? this.hikingTrails = this.hikingTrailsMain  : this.permits = this.permitsMain;

  }
  public filterItems(event) {
    if(this.saleType == 'Hiking Trails'){
      if(event.target.value){
        this.hikingTrails = this.hikingTrails.filter((item) => ((item.name).toLowerCase()).indexOf((event.target.value).toLowerCase()) >= 0);
      } else { 
        this.hikingTrails = this.hikingTrailsMain; 
      }
    } else { 
      if(event.target.value){
        this.permits = this.permits.filter((item) => ((item.name).toLowerCase()).indexOf((event.target.value).toLowerCase()) >= 0);
      } else { 
        this.permits = this.permitsMain; 
      }
    }
  }

}
