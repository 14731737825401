<section no-padding>
  <div class="row-main">
    <div *ngFor="let bar of bars; let i = index" class="bar">
      <div [ngClass]="index > i ? 'item-round item-round-inactive active' : 'item-round'">
        <!-- <span class="count"></span> -->
        <span *ngIf="!(index > i)" class="count">
          <img src="../../../../../assets/images/payment-icons/right-mark.svg" class="right-mark" alt="">
        </span>

        <span *ngIf="(index > i)" class="count">
          <img src="../../../../../assets/images/payment-icons/right-mark.svg" class="right-mark" alt="">
        </span>

      </div>
      <hr [ngClass]="index - 1 > i ? 'active-hr' : ''" [hidden]="i + 1 == bars?.length" class="line">
      <h6 class="title">{{bar}}</h6>
    </div>
  </div>
</section>