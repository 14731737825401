import { FirebaseError } from "firebase";


export function parseFirebaseError(error : FirebaseError) : FirebaseError {
    switch (error.code) {
        case "auth/user-not-found":
                error.message = "Email not registered, please try again."
                return error;
        
        case "auth/wrong-password":
               error.message = "Wrong password, please try again.";
               return error;

        
        case "auth/too-many-requests":
                error.message = "Account locked or deleted, please try again after a while.";
                return error;
    
        default:
            return error;
    }
}