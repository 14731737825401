<div class="container component-wrapper full-height">

    <div class="form-container">
        <form [formGroup]="loginForm">
            <mat-spinner *ngIf="loading"></mat-spinner>
            <div [hidden]="loading">
                <img class="logo-login" src="../../../../assets/images/logos/mto-trails.logo.svg" alt="">
                <h1 class="form-title" align="center"></h1>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Email Address</mat-label>
                    <input matInput class="form-control" formControlName="emailAddress">
                    <mat-error *ngIf="submitted && loginFormControls.emailAddress.errors">
                        <span *ngIf="loginFormControls.emailAddress.errors.required">Email is required</span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="password full-width">
                    <mat-label>Password</mat-label>
                    <input matInput class="form-control" [type]="hide ? 'password' : 'text'" formControlName="password">
                    <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="submitted && loginFormControls.password.errors">
                        <span *ngIf="loginFormControls.password.errors.required">Password is required</span>
                    </mat-error>
                </mat-form-field>
                <button mat-button (click)="loginFormSubmit()" class="loginSubmitButton standard-button full-width m-t-5">Login</button>
                <h6 align="center" class="help-text">Please contact MTO IT support if you are experiencing issues accessing your account. </h6>
            </div>

        </form>
    </div>

</div>