import { BaseLayoutComponent } from "./layouts/base-layout/base-layout.component";
import { ProgressBarComponent } from "./general/progress-bar/progress-bar.component";
import { ToolbarComponent } from "./layouts/toolbar/toolbar.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoginComponent } from "./pages/login/login.component";
import { NoAccessComponent } from "./pages/no-access/no-access.component";
import { LoaderComponent } from "./general/loader/loader.component";
import { TableComponent } from "./general/table/table.component";
import { BreadCrumbComponent } from './general/bread-crumb/bread-crumb.component';
import { TableSearchComponent } from './general/table-search/table-search.component';
import { RichtextEditorComponent } from "./general/richtext-editor/richtext-editor.component";
import { SitesComponent } from "./pages/admin/sites/sites.component";
import { SiteEditComponent } from "./pages/admin/sites/site-edit/site-edit.component";
import { SiteCreateComponent } from "./pages/admin/sites/site-create/site-create.component";
import { CreateActivityDialog } from "./pages/admin/sites/site-edit/create-activity-dialog/create-activity-dialog";
import { UpdateActivityDialog } from "./pages/admin/sites/site-edit/update-activity-dialog/update-activity-dialog";
import { RightpaneComponent } from "./pages/sales/rightpane/rightpane.component";
import { LeftpaneComponent } from "./pages/sales/leftpane/leftpane.component";
import { SaleComponent } from "./pages/sales/sale/sale.component";
import { BookingsComponent } from "./pages/admin/bookings/bookings/bookings.component";
import { CalendarComponent } from "./pages/admin/bookings/calendar/calendar.component";


export const Components = [

    //General
    LoaderComponent,
    TableComponent,
    BreadCrumbComponent,
    TableSearchComponent,
    RichtextEditorComponent,

    //Dialogs
    CreateActivityDialog,
    UpdateActivityDialog,

    //layouts
    BaseLayoutComponent,
    ProgressBarComponent,
    ToolbarComponent,

    //Pages
    DashboardComponent,
    LoginComponent,
    NoAccessComponent,
    
    //Sales
    RightpaneComponent,
    LeftpaneComponent,
    SaleComponent,

    BookingsComponent,
    CalendarComponent,

    SitesComponent,
    SiteEditComponent,
    SiteCreateComponent,
    // PushNotificationsComponent
]