import { FirebaseModule } from "./firebase/firebase.module";
import { MaterialModule } from "./material/material.module";
import { AppRoutingModule } from "./routing/app-routing.module";
import { PipesModule } from './shared/pipes.module';

import { ScheduleModule } from "@syncfusion/ej2-angular-schedule";

export const MODULES = [
    MaterialModule,
    AppRoutingModule,
    FirebaseModule,
    PipesModule,
    ScheduleModule
]