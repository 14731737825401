import { Injectable } from '@angular/core';
import { CONFIG } from 'src/app/configuration/app-config';
import { HttpService } from '../networking/rest.service';
import { SearchFilter } from 'src/app/library/interfaces/general/SearchFilter';
import { State } from 'src/app/state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(
    private restService: HttpService,
    private stateService : State
    ) {
  }


  public start() {
    try {
      this.getAllResources();
    } catch (error) {
      console.error(error);
    }
  }


  private async getAllResources() {
    let results = await this.restService.get(`${CONFIG.API.CORE.url}eco/sites`);
    this.stateService.sites = results.value;
  }

  // public async getSiteById(siteId: number): Promise<Site> {
  //   return this.restService.get(`${CONFIG.API.ADMIN.url}/Site/${siteId}`)
  // }
  // public async getSiteProductsByType(siteId: number, productType: ProductType, searchFilter?: SearchFilter): Promise<PagedResult<Product>> {
  //   return this.restService.get(`${CONFIG.API.ADMIN.url}/Site/${siteId}/Products/${productType}`, searchFilter);
  // }

  // public async getSiteActivities(siteId: number, searchFilter?: SearchFilter): Promise<PagedResult<SiteActivity>> {
  //   return this.restService.get(`${CONFIG.API.ADMIN.url}/Site/${siteId}/Activities`, searchFilter);
  // }

  // public async getSiteProducts(siteId: number, searchFilter?: SearchFilter): Promise<PagedResult<Product>> {
  //   return this.restService.get(`${CONFIG.API.ADMIN.url}/Site/${siteId}/Products`, searchFilter);

}

