import { Injectable, Component, NgZone } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class IconRegistry {

    icons = [

    ]

    constructor(private sanitizer : DomSanitizer, private iconRegistry : MatIconRegistry ) {  }

    public registerIcons() : void { 
        for (const icon of this.icons) {
            this.iconRegistry.addSvgIcon(
                icon.name,
                this.sanitizer.bypassSecurityTrustResourceUrl(icon.url));
        }
        
    }
}
