import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class UialertsService {

  constructor(private snackBar: MatSnackBar, private zone: NgZone) { }

  public openSnackBar(options: { duration: number, message: string, mode: string }): void {
    this.zone.run(() => {
      this.snackBar.open(options.message, null, {
        duration: options.duration * 1000,
        horizontalPosition: 'center',
        panelClass: `snackbar-${options.mode}`
      });
    });
  }
}
