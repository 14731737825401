import { Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../networking/rest.service';

@Injectable({
  providedIn: 'root'
})

export class PathService {

    currentPath : BehaviorSubject<string> = new BehaviorSubject('');

    public set(path : string) : void { 
        this.currentPath.next(path);
    }

    public get() : BehaviorSubject<string> {
        return this.currentPath;
    }

}
