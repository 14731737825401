import { Injectable } from "@angular/core";
import { FirebaseApp } from "@angular/fire";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
    providedIn: "root"
})

export class FirebaseService {

    constructor(
        public auth: AngularFireAuth
    ) { }

    public async start(): Promise<void> {

        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve(null); //hack - give firebase enough time to init
            }, 4000);
        })
    }

    public async getToken() {
        let token: string = await this.auth.currentUser.then(async user => {
            if (user) {
                return await user.getIdToken(false);
            } else {
                return '';
            }
        });

        return token;
    }

}