<h2 mat-dialog-title>Create activity</h2>
<mat-divider class="bold-divider"></mat-divider>
<mat-dialog-content class="mat-typography">

    <div *ngIf="!loading; else loader" class="standard-card tm-30">
        <form [formGroup]="form1" class="row full-width">
            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput class="form-control" formControlName="name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput class="form-control" formControlName="description">
                </mat-form-field>
            </div>

            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="activity" class="form-control">
                        <mat-option [value]="activity.id" *ngFor="let activity of activities">
                            <small>{{activity.name}}</small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Difficulty</mat-label>
                    <mat-select formControlName="difficulty" class="form-control">
                        <mat-option [value]="difficulty.id" *ngFor="let difficulty of difficulty_type">
                            <small>{{difficulty.name}}</small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Distance (Km)</mat-label>
                    <input matInput type="number" class="form-control" formControlName="distanceKM">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Elevation (m)</mat-label>
                    <input matInput type="number" class="form-control" formControlName="elevationMeters">
                </mat-form-field>
            </div>

            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label> Duration (minutes)</mat-label>
                    <input matInput type="number" class="form-control" formControlName="durationMinutes">
                </mat-form-field>
            </div>
            <hr>
            <div class="mat-title">Location</div>

            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Latitude</mat-label>
                    <input matInput type="number" class="form-control" formControlName="latitude">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Longitude</mat-label>
                    <input matInput type="number" class="form-control" formControlName="longitude">
                </mat-form-field>
            </div>

        </form>
    </div>

    <ng-template #loader>
        <div class='spinner-loader table-loader'>
            <mat-spinner class='center'></mat-spinner>
        </div>
    </ng-template>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button class="standard-button" [mat-dialog-close]="true" [disabled]="form1?.invalid" (click)="createActivity();" mat-button cdkFocusInitial><small>Create activity</small></button>
</mat-dialog-actions>