import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CONFIG } from 'src/app/configuration/app-config';
import { HttpService } from 'src/app/services/networking/rest.service';
import { UialertsService } from 'src/app/services/ui/ui-alerts.service';

@Component({
  selector: 'app-site-create',
  templateUrl: './site-create.component.html',
  styleUrls: ['./site-create.component.scss']
})
export class SiteCreateComponent implements OnInit {

  form1 : FormGroup;
  componentLoading : boolean = false;

  constructor(private formBuilder : FormBuilder, private http : HttpService, private snackBar : UialertsService, private router : Router) { }

  ngOnInit(): void {
    this.buildForms();
  }

  private buildForms() : void { 
    this.form1 = this.formBuilder.group({
      name        : new FormControl(null, [Validators.required]),
      description : new FormControl(null, [Validators.required]),
      ecotourism  : new FormControl(null, [Validators.required]),
      latitude    : new FormControl(null, [Validators.required]),
      longitude   : new FormControl(null, [Validators.required])
    })
  }

  public async createSite() {
    try {
      this.componentLoading = true;
      let newSite = this.form1.getRawValue(); 

      newSite['coordinates'] = {
        latitude : newSite.latitude,
        longitude: newSite.longitude
      }

      delete newSite.latitude;
      delete newSite.longitude;

      // await this.http.post(`${CONFIG.API.ADMIN.url}/site/`,newSite);
      this.snackBar.openSnackBar({
        duration : 3,
        message  : "Site created",
        mode     : 'success'
      });

      this.router.navigate(['resources/sites']);

    } catch (error) {
      this.componentLoading = false;
      this.snackBar.openSnackBar({
        duration : 3,
        message  : "Site creation failed",
        mode     : "danger"});
        
        console.error(error);
    }
  }

}
